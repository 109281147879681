<template>
  <b-card-actions title="Students" action-collapse :collapsed="false" no-body class="m-0">
    <div class="m-2 mb-0">
      <b-row>
        <!-- Per Page -->
        <b-col cols='12' md='6' class='d-flex align-items-center justify-content-start mb-1 mb-md-0'>
          <label>Show</label>
          <v-select v-model='perPage' :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options='perPageOptions'
            :clearable='false' class='per-page-selector d-inline-block mx-50' />
          <label>entries</label>

          <b-form-checkbox switch class='ml-5' v-model='editMode.enabled'>Edit</b-form-checkbox>

          <b-dropdown v-if='editMode.selected.length > 0' variant='primary' text='Update Status' class='ml-1'>
            <b-dropdown-item @click='updateSelectedProfilesStatus("enrolled")'>Enrolled</b-dropdown-item>
            <b-dropdown-item @click='updateSelectedProfilesStatus("graduated")'>Graduated</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click='updateSelectedProfilesStatus("withdrew_s1")'>Withdrew S1</b-dropdown-item>
            <b-dropdown-item @click='updateSelectedProfilesStatus("withdrew_s2")'>Withdrew S2</b-dropdown-item>
            <b-dropdown-item @click='updateSelectedProfilesStatus("withdrew_s3")'>Withdrew S3</b-dropdown-item>
            <b-dropdown-item @click='updateSelectedProfilesStatus("terminated")'>Terminated</b-dropdown-item>
          </b-dropdown>


          <b-form-checkbox switch class='ml-5' v-model='isCurrent'>{{ isCurrent ? 'Current' : 'Reassigned' }}</b-form-checkbox>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />

            <b-button variant="primary" @click="isAddNewStudentActive = true">
              <span class="text-nowrap">Add Student</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table ref='refStudentsListTable' class='position-relative' :items.sync='fetchProfiles'
      :fields="editMode.enabled ? [{ key: 'multiSelect', label: '', stickyColumn: true, variant: 'primary', sortable: false }, ...tableColumns] : tableColumns"
      responsive primary-key='id' :sort-by.sync='sortBy' :sort-desc.sync='isSortDirDesc' :sort-by-formatted='true'
      show-empty empty-text='No matching records found' striped>
      <!-- Column: multi select -->
      <template #head(multiSelect)='data'>
        <b-form-checkbox :checked='isAllChecked' @change='val => setAllChecked(val)' class='p-0'></b-form-checkbox>
      </template>

      <!-- Column: Session -->
      <template #cell(multiSelect)='data'>
        <b-form-checkbox :checked='isProfileSelected(data.item.id)' @change='val => updateProfileSelected(data.item.id, val)' class='p-0'></b-form-checkbox>
      </template>

      <!-- Column: studentId -->
      <template #cell(studentId)='data'>
        {{ data.item.student.studentId }}
      </template>

      <!-- Column: Session -->
      <template #cell(name)='data'>

        <div class='d-block '>
          <router-link v-if='data.item.reassignFrom'
            :to="{ name: 'apps-roster-session-view', params: { id: (!isCurrent ? data.item.session.id : data.item.reassignFrom._id) } }"
            class='font-weight-bold text-nowrap' target='_blank'>
            <b-badge pill :variant='`light-warning`' class='text-capitalize'
              :id='`profile-${data.item.id}-reassign-${(!isCurrent ? data.item.session.id : data.item.reassignFrom._id)}-tooltip`'>
              <feather-icon :icon="isCurrent ? 'ArrowLeftIcon' : 'ArrowRightIcon'" />
            </b-badge>
            <b-tooltip :title='isCurrent ? data.item.reassignFrom.name : data.item.session.name' class='cursor-pointer'
              :target='`profile-${data.item.id}-reassign-${(!isCurrent ? data.item.session.id : data.item.reassignFrom._id)}-tooltip`' />
          </router-link>

          <b-link :to="{ name: 'apps-students-view', params: { id: data.item.student.id } }"
            class='font-weight-bold text-nowrap'>


            {{ data.item.student.fullName }}
          </b-link>
        </div>
        <small class="text-muted">#{{ data.item.student.studentId }}</small>
      </template>

      <!-- Column: Session -->
      <template #cell(email)="data">
        {{ data.item.student.email }}
      </template>

      <!-- Column: Attributes - Program -->
      <template #cell(fundingSource)="data">
        <b-badge pill :variant="`light-${resolveProfileProgramVariant(data.item.attributes.fundingSource)}`"
          class="text-capitalize">
          {{ title(data.item.attributes.fundingSource) }}
        </b-badge>
      </template>

      <!-- Column: Attributes - Payment Status -->
      <template #cell(paymentStatus)="data">
        <b-badge pill :variant="`light-${resolveProfilePaymentStatusVariant(data.item.attributes.paymentStatus)}`"
          class="text-capitalize">
          {{ title(data.item.attributes.paymentStatus) }}
        </b-badge>
      </template>

      <!-- Column: Attributes - Uploaded Resume -->
      <template #cell(uploadedResume)="data">
        <feather-icon :id="`profile-row-${data.item.id}-upload-resume-icon`" icon="UploadIcon" class="cursor-pointer"
          size="16" @click="startUpload(data.item, 'enrollment', 'resume')" />

        <b-tooltip title="Upload Resume" class="cursor-pointer"
          :target="`profile-row-${data.item.id}-upload-resume-icon`" />

        <b-badge pill :variant="`light-${resolveProfileYNVariant(data.item.attributes.uploadedResume)}`"
          class="text-capitalize">
          {{ title(data.item.attributes.uploadedResume) }}
        </b-badge>
      </template>

      <!-- Column: Attributes - Uploaded COE -->
      <template #cell(uploadedCoe)="data">
        <feather-icon :id="`profile-row-${data.item.id}-upload-coe-icon`" icon="UploadIcon" class="cursor-pointer"
          size="16" @click="startUpload(data.item, 'enrollment', 'coe')" />

        <b-tooltip title="Upload COE" class="cursor-pointer" :target="`profile-row-${data.item.id}-upload-coe-icon`" />

        <b-badge pill :variant="`light-${resolveProfileYNVariant(data.item.attributes.uploadedCoe)}`"
          class="text-capitalize">
          {{ title(data.item.attributes.uploadedCoe) }}
        </b-badge>
      </template>

      <!-- Column: Attributes - Uploaded Transcript -->
      <template #cell(uploadedTranscript)="data">
        <feather-icon :id="`profile-row-${data.item.id}-upload-transcript-icon`" icon="UploadIcon" class="cursor-pointer"
          size="16" @click="startUpload(data.item, 'transcript', 'prior')" />

        <b-tooltip title="Upload Transcript" class="cursor-pointer"
          :target="`profile-row-${data.item.id}-upload-transcript-icon`" />

        <b-badge pill :variant="`light-${resolveProfileYNVariant(data.item.attributes.uploadedTranscript)}`"
          class="text-capitalize">
          {{ title(data.item.attributes.uploadedTranscript) }}
        </b-badge>
      </template>

      <!-- Column: Attributes - VA Submission -->
      <template #cell(submittedToVa)="data">
        <feather-icon :id="`profile-row-${data.item.id}-update-submission`" icon="CheckCircleIcon" class="cursor-pointer"
          size="16" @click="markProfileAsSubmitted(data.item)" v-if="data.item.attributes.submittedToVa === 'no'" />

        <b-tooltip title="Mark as Submitted" class="cursor-pointer"
          :target="`profile-row-${data.item.id}-update-submission`" />

        <b-badge pill :variant="`light-${resolveProfileYNVariant(data.item.attributes.submittedToVa)}`"
          class="text-capitalize">
          {{ title(data.item.attributes.submittedToVa) }}
        </b-badge>
      </template>

      <!-- Column: Total Students -->
      <template #cell(createdAt)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">
            {{ moment(data.item.createdAt).format('L') }}
          </span>
        </div>
      </template>

      <!-- Column: Total Students -->
      <template #cell(sessionDate)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">
            {{ moment(data.item.sessionDate).format('L') }}
          </span>
        </div>
      </template>


      <!-- Column: Transfer of Credit -->
      <template #cell(toc)="data">
        <b-badge pill :variant="`light-${resolveProfileYNVariant(data.item.toc.enabled)}`" class="text-capitalize">
          {{ title(data.item.toc.enabled ? 'Yes' : 'No') }}
        </b-badge>
      </template>


      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge pill :variant="`light-${resolveProfileStatusVariant(data.item.status)}`" class="text-capitalize">
          {{ title(data.item.status) }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
          </template>

          <b-dropdown-item :to="{ name: 'apps-roster-profile-edit', params: { id: data.item.id } }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="$can('roster.session.student.transfer')" @click='openTransferSessionModal(data.item)'>
            <feather-icon icon="MoveIcon" />
            <span class="align-middle ml-50">Transfer</span>
          </b-dropdown-item>
          <b-dropdown-item @click='openCourseToCModal(data.item, true)'>
            <!-- v-if="$can('roster.session.student.credit')"  -->
            <feather-icon icon="CreditCardIcon" />
            <span class="align-middle ml-50">Add Credit</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="data.item.toc.enabled" @click='openCourseToCModal(data.item, false)'>
            <!-- v-if="$can('roster.session.student.credit')"  -->
            <feather-icon icon="CreditCardIcon" />
            <span class="align-middle ml-50">Remove Credit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="removeStudentsFromSession([data.item.id])">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Remove</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="totalProfiles" :per-page="perPage" first-number last-number
            class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <session-view-add-student @refreshStudents="refetchData" :session-data="sessionData"
      :is-add-new-student-active.sync="isAddNewStudentActive" />
    <student-add-to-session @refresh="refetchData" v-if="isAddSessionOpen" :is-add-session-open.sync='isAddSessionOpen'
      :profile-data='selectedTransferProfile'
      :override-pathway='sessionData.metadata.primaryPathway ? sessionData.metadata.primaryPathway.id : undefined' />
    <profile-upload-document :is-upload-document-open.sync='upload.isUploadDocumentOpen' :profile='upload.profile'
      :document-category='upload.documentCategory' :document-type='upload.documentType' @refresh='refetchData' />


    <b-modal id='modal-toc' ok-title='Submit' centered size='md'
      :title='`Please select a course to ${tocEdit.isAssign ? "assign credit for" : "remove credit from"}`' ref='modal'
      @hidden="() => tocEdit.isModalOpen = false" :visible='tocEdit.isModalOpen' @ok='() => submitProfileToC()'
      :ok-disabled="!tocEdit.course || tocEdit.isAssign && tocEdit.note.length === 0"
      >
      <b-card>
        <b-row>
          <b-col cols="12">
            <b-form-group label='ToC Course' label-for='toc-course'>
              <v-select v-model="tocEdit.course" :options="tocCourseOptions" :clearable="false" />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group v-if="tocEdit.isAssign" label='ToC Notes' label-for='toc-notes'>
              <b-form-input id='toc-notes' v-model='tocEdit.note' autofocus :state='tocEdit.isAssign && tocEdit.note.length > 0 || !tocEdit.isAssign' trim placeholder='' />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>

    <b-modal id='modal-status-reason' ok-title='Submit' centered size='lg' title='Provide a Reason for Status Update'
      ref='modal' @hidden="() => editMode.isStatusReasonOpen = false" :visible='editMode.isStatusReasonOpen'
      @ok='() => updateSelectedProfilesStatus()'>
      <b-card>
        <b-row>
          <b-col>

            <b-form-group label='Reason' label-for='status-reason'>
              <b-form-input id='transfer-reason' v-model='editMode.statusReason' autofocus
                :state='editMode.statusReason && editMode.statusReason.length > 0' trim placeholder='' />
            </b-form-group>

          </b-col>
        </b-row>
      </b-card>
    </b-modal>
  </b-card-actions>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BLink,
  BMedia,
  BModal,
  BPagination,
  BRow,
  BTable,
  BTooltip
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { avatarText, title } from '@core/utils/filter';
import useSessionStudentsList from './useSessionStudentsList';
import rosterStoreModule from '../rosterStoreModule';
import moment from 'moment';

import SessionViewAddStudent from './SessionViewAddStudent';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import ProfileUploadDocument from '@/views/apps/roster/sessions-view/components/ProfileUploadDocument';
import StudentAddToSession from '@/views/apps/student/students-view/components/StudentAddToSession.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent';

export default {
  components: {
    StudentAddToSession,
    ProfileUploadDocument,
    SessionViewAddStudent,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BPagination,
    BCardBody,
    BCardTitle,
    BCardActions,
    BCardText,
    BModal,
    BTooltip,
    ToastificationContent,
    vSelect
  },
  data: () => {
    return {
      isAddNewStudentActive: false,

      isAddSessionOpen: false,
      selectedTransferProfile: {},
      editClassData: {},

      upload: {
        isUploadDocumentOpen: false,

        profile: undefined,
        documentType: undefined,
        documentCategory: undefined
      },

      tocEdit: {
        enabled: false,
        isModalOpen: false,
        isAssign: true,

        profile: undefined,
        course: undefined,
        note: '',
      },

      editMode: {
        enabled: false,
        selected: [],

        isStatusReasonOpen: false,
        status: '',
        statusReason: ''
      }
    };
  },
  props: {
    sessionData: { type: Object, required: true },
    sessionId: { type: String }
  },
  emits: ['refresh'],
  computed: {
    isAllChecked() {
      return this.idsOnPage.filter(id => this.editMode.selected.indexOf(id) === -1).length === 0
    },
    tocCourseOptions() {
      return this.sessionData.courses.filter(course => {
        if (this.selectedTransferProfile.courses && this.selectedTransferProfile.courses[course.id])
          return this.tocEdit.isAssign ? !this.selectedTransferProfile.courses[course.id].toc?.enabled : this.selectedTransferProfile.courses[course.id]?.toc?.enabled;

        return true;
      }).map(course => { return { label: course.name, value: course.id } })
    }
  },
  methods: {
    openCourseToCModal(profile, isAssign) {
      this.selectedTransferProfile = profile;
    
      this.tocEdit.isAssign = isAssign;
      this.tocEdit.profile = profile;
      this.tocEdit.isModalOpen = true;
      this.tocEdit.course = this.tocCourseOptions[0]
    },
    submitProfileToC() {

      let { profile, isAssign } = this.tocEdit;
      const courseId = this.tocEdit.course.value;
      profile.courses[courseId].toc = isAssign ? {
        enabled: true,
        notes: this.tocEdit.note
      } : { enabled: false };

      console.log(this.tocEdit, isAssign, profile.courses[courseId]);

      (
        isAssign ?
          store.dispatch(`app-roster/assignCourseCredit`, { id: profile.id, creditData: { enabled: true, notes: this.tocEdit.note, courseId: courseId } }) :
          store.dispatch(`app-roster/unassignCourseCredit`, { id: profile.id, creditData: { enabled: false, courseId: courseId } })
      )
        .then((response) => {

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Credit assignment edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });

          this.tocEdit.course = undefined;
          this.tocEdit.note = '';
          this.refetchData();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit profile',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },


    setAllChecked(val) {
      // if all of our current vals are selected and we're trying to update, noop
      if (val && this.isAllChecked) {
        return
      }

      if (val) {
        // when checking, add all of the ids on the current visible page to our id buffer
        this.editMode.selected = this.editMode.selected.concat(this.idsOnPage).filter((id, index, items) => items.indexOf(id) === index)
      } else {
        // when checking, remove visible ids from the id list
        this.editMode.selected = this.editMode.selected.filter(id => this.idsOnPage.indexOf(id) === -1);
      }
    },
    isProfileSelected(profileId) {
      return this.editMode.selected.includes(profileId);
    },
    updateProfileSelected(profileId, value) {
      if (value) {
        this.editMode.selected.push(profileId);
      } else {

        this.editMode.selected.splice(this.editMode.selected.indexOf(profileId), 1);
      }

      console.log(JSON.stringify(this.editMode.selected));
    },
    updateSelectedProfiles(profileIds, body) {

    },
    updateSelectedProfilesStatus(_status) {
      const status = _status ? _status : this.editMode.status;
      const profileIds = this.editMode.selected;
      const reason = this.editMode.statusReason;

      if (['withdrew_s1', 'withdrew_s2', 'withdrew_s3', 'terminated'].includes(status) && !reason) {
        this.editMode.status = status;
        this.editMode.isStatusReasonOpen = true;
        return;
      }

      this.editMode.statusReason = '';

      this.$swal({
        title: 'Are you sure?',
        text: `You're setting ${profileIds.length} profiles to <${title(status)}>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, update!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {

          console.log(`update following profiles to the ${status} status for reason "${reason}"`, profileIds);

          store
            .dispatch('app-roster/updateProfiles', {
              ids: profileIds,
              profileData: { status, statusReason: reason, statusDate: new Date() }
            })
            .then((response) => {
              this.refStudentsListTable.refresh();
              this.editMode.selected = [];
              this.editMode.status = '';
              this.editMode.statusReason = '';
            });
        }
      });
    },
    openTransferSessionModal(profile) {
      this.selectedTransferProfile = profile;
      this.isAddSessionOpen = true;
    },
    refetchData() {
      this.$emit('refresh', true);
      setTimeout(this.refetchStudents, 500)
    },
    startUpload(profile, documentCategory, documentType) {
      this.upload = {
        isUploadDocumentOpen: true,
        profile,
        documentCategory,
        documentType,
      };
    },

    markProfileAsSubmitted(profile) {
      store
        .dispatch('app-roster/updateProfile', {
          id: profile.id,
          profileData: {
            attributes: { ...profile.attributes, submittedToVa: 'yes' },
          },
        })
        .then((response) => {
          this.refetchData();
        });
    },
    async removeStudentsFromSession(profileIds) {
      if (!profileIds || profileIds.length === 0) {
        console.log(`no students selected or invalid id lsit`);
        return;
      }

      const response = await this.$store
        .dispatch('app-roster/removeStudentsFromSession', {
          sessionId: this.sessionData.id,
          profileIds,
        })
        .then(() => {
          this.refetchData();
        })
        .catch((error) => {
          console.error(`failed to remove students to class`, error);
        });

      if (!response) return false;

      console.log(`added removed`, response.data);
    },
    assignNewStudent() {
    },
    doClassEdit(clazz) {
      console.log(`editing`, clazz);
    }
  },
  mounted() {

  },
  setup(props) {
    const SESSION_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(SESSION_APP_STORE_MODULE_NAME))
      store.registerModule(SESSION_APP_STORE_MODULE_NAME, rosterStoreModule);

    const {
      perPage,
      currentPage,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refStudentsListTable,
      dataMeta,

      refetchData: refetchStudents,
      tableColumns,
      fetchProfiles,
      searchQuery,
      totalProfiles,
      idsOnPage,
      isCurrent,

      resolveProfileStatusVariant,
      resolveProfileProgramVariant,
      resolveProfilePaymentStatusVariant,
      resolveProfileYNVariant,
    } = useSessionStudentsList(props.sessionId);

    return {
      // Sidebar

      fetchProfiles,
      refetchStudents,

      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refStudentsListTable,
      totalProfiles,
      idsOnPage,
      isCurrent,

      dataMeta,

      moment,

      searchQuery,

      resolveProfileStatusVariant,
      resolveProfileProgramVariant,
      resolveProfilePaymentStatusVariant,
      resolveProfileYNVariant,

      // Filter
      avatarText,
      title,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.position-relative {
  min-height: 165px !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
