<template>
  <div>
    <b-modal class="px-0" @hide="$emit('update:is-table-open', false)" :visible="isTableOpen" id="modal-attendance"
      hide-footer centered size="xl" :title="`Take Attendance for ${sessionData.name}`">
      <b-card-text>
        <b-button v-if="false" v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-attendance-attributes
          variant="outline-primary">
          edit attributes
        </b-button>

        <b-card no-body class="mb-0">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <label>Show</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>

                <b-form-checkbox class='ml-5' v-model='isFocusEnrolled'>Focus</b-form-checkbox>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
                </div>
              </b-col>
            </b-row>
          </div>

          <b-table
            v-if="isTableActive"
            id="table-attendance"
            ref="refAttendanceTable"
            class="position-relative"
            :items="fetchAttendance"
            responsive
            :fields.sync="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            sticky-header="70vh"
            :busy='isTableLoading'
          >

            <template #thead-top="data">
              <b-tr>
                <b-th colspan="1"><span class="sr-only">Student</span></b-th>
                <b-th :variant="index % 2 === 0 ? `primary` : 'warning' " :key="course.id" v-for="(course, index) in addlHeader" :colspan="course.classes.length || 1">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        size="24"
                        :src="course.avatar"
                        :text="avatarText(course.name)"
                        :variant="`light-primary`"
                      />
                    </template>
                    <span class="font-weight-bold d-block text-nowrap">
                      {{ course.name }}
                    </span>
                  </b-media>
                </b-th>
              </b-tr>
            </template>

            <template v-for="field in dynamicTableColumns" v-slot:[`head(${field.key})`]="{ item }">
              <div class='d-inline-flex'>
                {{field.label}}
                <b-button size='sm' pill class='cursor-pointer badge badge-pill' style='margin-left:5px;' variant='success' @click='openCheckinModal(field.key, field.label)'>+</b-button>
              </div>
            </template>

            <template #cell(student.fullName)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="data.item.student.avatar"
                    :text="avatarText(`${data.item.student.firstName} ${data.item.student.lastName}`)"
                    :variant="`light-primary`"
                    :to="{ name: 'apps-students-view', params: { id: data.item.student.id } }"
                  />
                </template>

                <b-link :to="{ name: 'apps-students-view', params: { id: data.item.student.id } }"
                  class="font-weight-bold d-block text-nowrap">
                  <b-badge v-if="data.item.status === 'reassigned'" variant="warning" pill>
                  R
                </b-badge>
                  {{ data.item.student.fullName }}
                </b-link>
                <small class="text-muted d-inline">
                  #{{ data.item.student.studentId }}

                  <router-link :to="{ name: 'apps-roster-profile-edit', params: { id: data.item.profile._id } }">
                    <b-badge :variant="variantOfStatus(data.item.profile.status)" class='float-right'>
                      {{ title(data.item.profile.status) }}
                    </b-badge>
                  </router-link>
                </small>

              </b-media>
            </template>

            <template v-for="field in dynamicTableColumns" v-slot:[`cell(${field.key})`]="{ item }">
              <div v-for="(checkin, index) in (item.record[field.key] || {checkins: []}).checkins" class="form-check form-check-inline float-right text-center">

                <template v-if="checkin.type === 'credited'">
                  <span class='text-nowrap'>{{ moment.parseZone(checkin.date).format('h:mm A') }} — ToC</span>
                </template>

                <template v-else>
                  <span class='text-nowrap'>{{ moment.parseZone(checkin.date).format('h:mm A') }}&nbsp;</span>

                  <b-form-checkbox class="custom-control-success"
                                  @change="updateAttendanceRecord(item, field.key, item.record[field.key])"
                                  v-model="checkin.attended"
                                  :disabled="checkin.type === 'credited'"
                                    />

                  <b-badge class="cursor-pointer" pill
                    :variant="resolveCheckinVariant(checkin)"
                    @click="openSubModal(item, field.key, index)">
                    <feather-icon icon="Edit3Icon" />
                  </b-badge>
                </template>
              </div>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col cols="12" md="6" class="d-flex text-middle align-items-center justify-content-start ">
                <span class="text-muted">
                  Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
                </span>
              </b-col>

              <!-- Pagination -->
              <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalAttendances"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>

      </b-card-text>
    </b-modal>

    <b-modal id="modal-attendance-attributes" :title="createTitleForModal()" ok-title="Save Changes"
             cancel-variant="outline-secondary" ref="attendance-sub-modal" @ok="saveSubModal" :ok-disabled='(attendanceRecord.type === "absent_excused" && !attendanceRecord.notes) || attendanceRecord === "reassigned"'>
      <b-form class="p-1">
        <b-form-group label="Attended?" label-for="attendance-attended">
          <v-select
            id="attendance-attended"
            v-model="attendanceRecord.attended"
            :reduce="(val) => val.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="attendedOptions"
            :clearable="false"
          />
        </b-form-group>
        <b-form-group label="Type" label-for="attendance-type">
          <v-select
            id="attendance-type"
            v-model="attendanceRecord.type"
            :reduce="(val) => val.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="typeOptions"
            :clearable="false"
          />
        </b-form-group>
        <b-form-group label="Notes" label-for="attendance-notes">
          <b-form-textarea v-model="attendanceRecord.notes" id="attendance-notes" />
        </b-form-group>

        <component is='div' v-if='attendance.profile && attendanceRecord.type === "reassigned" && attendance.profile.reassignReason'>
          <hr>
          <b-form-group label="Reassign Reason" label-for="reassign-reason">
            <b-form-textarea v-model="attendance.profile.reassignReason" disabled id="reassign-reason" />
          </b-form-group>
        </component>
      </b-form>
    </b-modal>

    <b-modal id="modal-attendance-checkin" :title="`Create Checkin`" ok-title="Create"
             cancel-variant="outline-secondary" ref="attendance-checkin-modal" @ok="saveCheckinModal">
      <b-form class="p-1">
        <b-form-group label="Time of Checkin" label-for="attendance-checkin-time">
          <b-form-timepicker :minutes-step='15' :now-button='true' id="attendance-checkin-time" v-model="createCheckin.time" />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BTr,
  BTh,
  BCard,
  BCardText,
  BButton,
  BAvatar,
  BMedia,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
  BFormTimepicker,
  BTable,
  BPagination,
  BLink,
  BBadge
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { onUnmounted, ref } from "@vue/composition-api";
import store from "@/store";
import rosterStoreModule from "../../rosterStoreModule";
import { avatarText, title } from '@core/utils/filter';
import moment from 'moment'

import useSessionAttendance from "@/views/apps/roster/sessions-view/useSessionAttendance";

export default {
  name: "AttendanceModal",
  directives: {
    Ripple
  },
  components: {
    BTr,
    BTh,
    BCard,
    BCardText,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BMedia,
    vSelect,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    BFormTimepicker,
    BTable,
    BPagination,
    BLink,
    BBadge
  },
  props: {
    sessionData: {
      type: Object
    },
    isTableOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      attendedOptions: [{ label: "Yes", value: true }, { label: "No", value: false }],
      typeOptions: [
        { label: "Present", value: "present" },
        { label: "Absent - Excused", value: "absent_excused" },
        { label: "Absent - Unexcused", value: "absent_unexcused" },
        { label: "Late", value: "late" },
        { label: "Special Case", value: "special_case" },
        { label: "Reassigned", value: "reassigned" },
        { label: "Credited", value: "credited" },
      ],
      createCheckin: {},
      isTimestampEnabled: true,
      isTableLoading: false,
    };
  },
  methods: {
    variantOfStatus(status) {
      return ['terminated', 'withdrew_s1', 'withdrew_s2', 'withdrew_s3', 'action_required'].includes(status) ? 'primary' : (
        ['denial_of_benefit'].includes(status) ? 'warning' :
          'info'
      )
    },
    title,
    openCheckinModal(classId, dateLabel) {
      this.createCheckin = { classId, date: dateLabel, time: '12:00:00' }
      this.$refs["attendance-checkin-modal"].show();
    },
    async saveCheckinModal() {
      this.isTableLoading = true
      const f = moment(this.createCheckin.date + ` ` + this.createCheckin.time);
      return store
        .dispatch("app-roster/createSessionClassCheckin", {
          sessionId: this.sessionData.id,
          classId: this.createCheckin.classId,
          dateTime: f.format()
        })
        .then(() => {
          this.isTableLoading = false;
          setTimeout(this.refetchData, 0)
        })
        .catch(err => {
          this.isTableLoading = false
          console.error(`failed to update attendance`, err);
        })

    },
    createTitleForModal() {
      if (!this.attendanceRecord) return `Record not loaded`
      return `Update Attendance for \n` + moment.parseZone(this.attendanceRecord.date).format('L h:mmA')
    },
    openSubModal(item, classId, checkinIndex) {
      this.attendance = { ...item }
      console.log(`opening modal for obj`, item.record[classId].checkins[checkinIndex]);
      this.attendanceRecord = { id: classId, index: checkinIndex, ...item.record[classId].checkins[checkinIndex] };
      this.$refs["attendance-sub-modal"].show();
    },
    resolveCheckinVariant(checkin) {
      if (checkin.type === 'credited') return 'secondary'
      return checkin.type === 'reassigned' ? 'warning' : checkin.notes || checkin.type !== 'present' ? 'primary' : 'info'
    },

    async saveSubModal() {

      const record = { ...this.attendanceRecord }
      const { id: classId, index: checkinIndex } = record;
      delete record.id
      delete record.index

      this.attendance.record[classId].checkins[checkinIndex] = record

      try {
        await this.updateAttendanceRecord(this.attendance)
        this.$refs["attendance-sub-modal"].hide();
      } catch (e) {

      }

      this.attendance = {}
      this.attendanceRecord = { ...this.attendanceDefault }

      this.refetchData()
    }
  },
  setup(props, { }) {

    const attendanceDefault = ref({ type: "present", attended: false, notes: "", date: '' });

    const attendance = ref({});
    const attendanceRecord = ref({ type: "present", attended: false, notes: "", date: '' });

    const isSubModalOpen = ref(false);
    const isTableActive = ref(false);

    const ROSTER_STORE_MODULE_NAME = "app-roster";

    // Register module
    if (!store.hasModule(ROSTER_STORE_MODULE_NAME)) store.registerModule(ROSTER_STORE_MODULE_NAME, rosterStoreModule);

    const {
      refAttendanceTable,
      fetchAttendance,
      tableColumns,
      dynamicTableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalAttendances,
      refetchData,
      sortBy,
      isSortDirDesc,
      dataMeta,
      searchQuery,
      isFocusEnrolled,
    } = useSessionAttendance(props.sessionData);

    const addlHeader = ref([])

    store
      .dispatch("app-roster/fetchSessionAttendanceTable", { id: props.sessionData.id })
      .then((response) => {
        const data = response.data;

        console.log(`setting columns:`);
        console.log(`receivabled table`, data);

        const { columns, courses } = data;

        for (let course of courses) {
          addlHeader.value.push(course)
        }

        tableColumns.push(...columns);

        let isInBefore = true
        dynamicTableColumns.push(...columns.map(col => {
          if (
            col.label === moment().format('L')
          ) {
            col.thAttr = { id: 'col-scroll' }
            col.variant = 'primary'
            col.isToday = true
          } else if (isInBefore && moment(col.label, 'MM/DD/YYYY').isAfter(moment())) {
            isInBefore = false;
            col.thAttr = { id: 'col-scroll' }
          }

          col.sortable = false;
          col.thClass = 'no-overlap'
          return col
        }));
        isTableActive.value = true;

        setTimeout(() => {
          const elem = document.getElementById("col-scroll");
          if (elem) elem.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'end' })
        }, 0)
      })
      .catch(err => {
        console.error(`failed to fetch table`, err);
      });

    fetchAttendance({}, cb => {
      console.log(`final`, cb);
    });

    const updateAttendanceRecord = async (item) => {
      return store
        .dispatch("app-roster/updateSessionAttendance", { id: item.id, data: { record: item.record } })
        .catch(err => {
          console.error(`failed to update attendance`, err);
        });
    };

    return {
      refAttendanceTable,
      fetchAttendance,
      tableColumns,
      dynamicTableColumns,
      addlHeader,
      perPage,
      perPageOptions,
      currentPage,
      totalAttendances,
      refetchData,
      sortBy,
      isSortDirDesc,
      avatarText,
      dataMeta,
      searchQuery,

      attendanceDefault,
      attendance,
      attendanceRecord,
      isTableActive,
      isFocusEnrolled,

      updateAttendanceRecord,
      isSubModalOpen,
      moment,
    };
  }
};
</script>

<style>
.modal-body {
  padding: 0 !important;
}

.no-overlap {
  top: 45px !important;
}

.table#table-attendance td {
  padding: .5rem !important;
}
</style>
